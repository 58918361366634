import { Link as LinkR } from 'react-router-dom';
import styled from "styled-components";
import { Document } from 'react-pdf';

export const Nav = styled.nav`
  background-color: black;
  height: 80px;
  display: flex;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 2;
  transition: 0.8s all ease;

  @media screen and (max-width: 991px) {
    transition: 0.8s all ease;
  }
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  width: 100%;
  padding: 0 24px;
`;

export const NavLogo = styled(LinkR)`
  color: white;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.6rem;
  display: flex;
  align-items: center;
  margin-left: 24px;
  font-weight: bold;
  text-decoration: none;

  @media (max-width: 760px) {
    font-size: 2rem;
    margin-left: 0;
  }

`;

export const ResumeWrapper = styled.div`
    background: #E8E8E8;
    display: flex;
    justify-content: center;
    padding: 40px;
    min-height: calc(100vh - 80px);
    width: 100%;

    @media (max-width: 693px) and (max-height: 1024px) {
      height: calc(100vh - 80px);
    }

  
`;

export const Doc = styled(Document)`
  position: static !important;
  transform-origin: top center;

  @media (max-width: 886px) {
          transform: scale(.9);
          /* height: 900px !important;
          width: 635.4px !important; */
        }

        @media (max-width: 847px) {
          transform: scale(.85);
          /* height: 800px !important;
          width: 564.8px !important;   */
        }

        @media (max-width: 809px) {
          transform: scale(.8);
          /* height: 700px !important;
          width: 494.2px !important; */
        }

        @media (max-width: 770px) {
          transform: scale(.75);
          /* height: 600px !important;
          width: 423.6px !important;  */
        }

        @media (max-width: 732px) {
          transform: scale(.7);
          /* height: 500px !important;
          width: 353px !important;  */
        }

        @media (max-width: 693px) {
          transform: scale(.45);
          /* height: 400px !important;
          width: 282.4px !important;  */
        }

`;
