import { FaBars } from 'react-icons/fa'
import { Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLinks } from "./NavbarElements";
import { animateScroll as scroll } from 'react-scroll';

const Navbar = ({isVisible, toggle, scrollNav}) => {

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo to='/' onClick={toggleHome}>starbinski</NavLogo>
          <MobileIcon onClick={ toggle }>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks activeClass="active" to='about' smooth={true} duration={1000} spy={true} exact="true" offset={-80}>About</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks activeClass={isVisible === true ? " " : "active"} to='projects' smooth={true} duration={1000} spy={true} exact="true" offset={-80}>Projects</NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks className={isVisible ? "active" : ""} to='contact' smooth={true} duration={1000} spy={true} exact="true" offset={-180}>Contact</NavLinks>
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
  );
};

export default Navbar;
