import styled from 'styled-components';

export const ProjectsContainer = styled.section`
  background: white;
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  min-height: 1000px;
  padding: 50px 50px;
  overflow: hidden;

  @media screen and (max-width: 1226px) {
    padding: 50px 0;
  }

  @media screen and (max-width: 756px) {
    padding: 50px 50px;
  }

  @media screen and (max-width: 500px) {
    padding: 50px 0;
  } 
    
`;

export const ProjectHeading = styled.h1`
  text-align: center;
  font-size: 42px;
  color: black;

  @media screen and (max-width: 991px) {
    font-size: 44px;
  }

`;

export const ProjectHr = styled.hr`
  border-style: solid;
  border-width: 4px 0 0 0;
  border-color: black;
  margin: 20px auto 20px;
  width: 80px;
`;

export const Subtitle = styled.p`
  margin: 10px auto 30px;
  font-size: 20px;
  line-height: 30px;
  color: black;
  font-family: 'Montserrat', sans-serif;

  @media screen and (max-width: 1226px) {
    padding: 0 20px;
  }

  @media screen and (max-width: 991px) {
    font-size: 22px;
  }

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`;

export const Email = styled.a`
  text-decoration: none;
  color: #00ADB5;
`;

export const CategoryList = styled.ul`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style: none;
  justify-content: space-between;
  max-width: 600px;
  width: 600px;
  position: relative;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 630px) {
    width: 450px;
  }

  @media screen and (max-width: 440px) {
    width: 375px;
  }

`;

export const CategoryBox = styled.li`
  background-color: #00ADB5;
  min-width: ${({selected}) => (selected.key === 'pluginsAndThemes' ? '200px' : '125px'  )};
  min-height: 39.6px;
  position: absolute;
  z-index: -1;
  border-radius: 5px;
  transform: translate(${({ selected }) => (
    selected.key === "pluginsAndThemes" ? "50px" :
    selected.key === "react" ? "385px" :
    selected.key === "node" ? "85px" : "388px" )
  }, ${({ selected }) => (
    (selected.key === 'node' || selected.key === 'javascript') ? "41px" : "0px")
  } );
  transition: all 0.4s cubic-bezier(0.02, 0.24, 0.45, 1.54);

  @media screen and (max-width: 630px) {
    transform: translate(${({ selected }) => (
    selected.key === "pluginsAndThemes" ? "13px" :
    selected.key === "react" ? "275px" :
    selected.key === "node" ? "51px" : "275px" )
  }, ${({ selected }) => (
    (selected.key === 'node' || selected.key === 'javascript') ? "41px" : "0px")
  } );
    transition: all 0.4s cubic-bezier(0.02, 0.24, 0.45, 1.54);
  }

  @media screen and (max-width: 440px) {
    transform: translate(${({ selected }) => (
    selected.key === "pluginsAndThemes" ? "18px" :
    selected.key === "react" ? "218px" :
    selected.key === "node" ? "32px" : "219px" )
  }, ${({ selected }) => (
    (selected.key === 'node' || selected.key === 'javascript') ? "66px" : "0px")
  } );
    min-height: ${({selected}) => (selected.key === 'pluginsAndThemes' ? '65px' : '39.6px'  )};
    min-width: ${({selected}) => (selected.key === 'pluginsAndThemes' ? '150px' : '125px'  )};
    transition: all 0.4s cubic-bezier(0.02, 0.24, 0.45, 1.54);
  }
  `;

export const Category = styled.li`
  font-size: 1.4rem;
  padding: 7px;
  cursor: pointer;
  min-width: 125px;
  transition: all 0.2s ease-in-out;
  position: relative;

  &.active {
    color: white;
  }

  @media screen and (max-width: 360px) {
    min-width: 120px;
  }

`;

export const AppList = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1110px;

  &.animate {
  animation-duration: .5s;
  animation-name: ${props => props.fadeDir};
  animation-timing-function: cubic-bezier(0.27,-0.49, 0.58, 1);

  @keyframes ${props => props.fadeDir} {
    from {
      transform: ${({fadeDir}) => (
        fadeDir === "fadeLeft" ? "translateX(-100px)" : 
        fadeDir === "fadeRight" ? "translateX(100px)" : "translateY(100px)")
      };
      opacity: 0;
    }
    to {
      transform: ${({fadeDir}) => (
        fadeDir === "fadeUp" ? "translateY(0)" : "translateX(0)" ) 
      };
      opacity: 1;
    }
  }
  }
`;

 
