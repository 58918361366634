import styled from "styled-components";
// import { AppButtons } from "../Global/ButtonElement";
import { BsGithub, BsLinkedin } from "react-icons/bs";
import { IoDocumentText } from "react-icons/io5";
import { MdEmail } from "react-icons/md";

export const ContactContainer = styled.section`
  background: #00ADB5;
  min-height: 550px;
  z-index: 1;
  position: relative;
  padding: 50px 50px 30px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 500px) {
    padding: 50px 0 30px;
  
  }
`;

export const Content = styled.div`
  flex: 1 1 auto;
  width: 100%;
`;

export const ContactHeader = styled.h1`
  font-size: 42px;
  text-align: center;
  
  @media screen and (max-width: 991px) {
    font-size: 44px;
  }
  
`;

export const ContactHr = styled.hr`
border-style: solid;
border-width: 4px 0 0 0;
border-color: white;
margin: 20px auto 20px;
width: 80px;
`;

export const ContactSubtitle = styled.p`
  font-size: 20px;
  line-height: 30px;
  font-family: 'Montserrat', sans-serif;
  margin: 0 auto;
  text-align: center;
  max-width: 550px;
  padding-top: 10px;
  
  @media screen and (max-width: 500px) {
      padding: 0 10px;
    }
`;

// export const ContactButton = styled(AppButtons)`
//   max-width: 154px;
//   background-color: #00ADB5;
//   border-color: white;
//   margin: 50px auto;
//   color: white;
//   text-decoration: none;
//   display: flex;
//   font-size: 20px;
//   padding: 12px 30px;
//
//   &:hover {
//     color: #00ADB5;
//     border-color: white;
//     background-color: white;
//   }
// `;

export const SocialsWrapper = styled.div`
  text-align: center;
  margin-top: 60px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  width: 100%;
  
`;


export const Github = styled(BsGithub)`
  color: white;
  margin: 0 30px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #6dc3c7;
    transition: all 0.2s ease-in-out;
    transform: translateY(-5px);
  }

  @media screen and (max-width: 500px) {
      margin: 0 20px;
    }
`;

export const LinkedIn = styled(BsLinkedin)`
  color: white;
  margin: 0 30px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #6dc3c7;
    transition: all 0.2s ease-in-out;
    transform: translateY(-5px);
  }

  @media screen and (max-width: 500px) {
      margin: 0 20px;
    }
`;

export const Resume = styled(IoDocumentText)`
  color: white;
  margin: 0 30px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #6dc3c7;
    transition: all 0.2s ease-in-out;
    transform: translateY(-5px);
  }

  @media screen and (max-width: 500px) {
      margin: 0 20px;
    }
`;

export const Email = styled(MdEmail)`
  color: white;
  margin: 0 30px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #6dc3c7;
    transition: all 0.2s ease-in-out;
    transform: translateY(-5px);
  }

  @media screen and (max-width: 500px) {
      margin: 0 20px;
    }
`;

export const FooterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FooterCopyright = styled.p`
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;

  @media screen and (max-width: 500px) {
      padding: 0 60px;
      text-align: center;
    }
`;
