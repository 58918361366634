import React from "react";
import {ContactContainer, Email, SocialsWrapper, ContactHeader, Content, ContactHr, ContactSubtitle, Github, LinkedIn, Resume, FooterWrapper, FooterCopyright} from "./ContactElements";
import { Link as LinkR } from 'react-router-dom';
// import { ViewportBlock } from "../pages";
import handleViewport from 'react-in-viewport';

const Block = (props) => {
  const {forwardedRef} = props;
  return (
  <FooterWrapper className="viewport-block" ref={forwardedRef}>
     <FooterCopyright>&copy; {new Date().getFullYear()} | Designed & Built by Mark Starbinski</FooterCopyright>
  </FooterWrapper>
    )
};

const ViewportBlock = handleViewport(Block);

const Contact = ({isVisible, setIsVisible}) => {

  const handleVisible = () => {
    if(isVisible === true){
      setIsVisible(false)
    } else setIsVisible(true);
  }

  return (
    <ContactContainer id="contact" title="Contact">
      <Content>
        <ContactHeader data-aos="fade-right">Get in Touch</ContactHeader>
        <ContactHr data-aos="fade-left"/>
        <ContactSubtitle data-aos="fade-right">Whether you're interested in working with me, or just have a question, my inbox is always open. Don't hesitate to reach out!</ContactSubtitle>
        <SocialsWrapper>
          <a href="https://github.com/mtstarbinski" data-aos="flip-right">
            <Github />
          </a>
          <a href="https://www.linkedin.com/in/mark-starbinski/" data-aos="flip-right" data-aos-delay="200">
            <LinkedIn />
          </a>
          <LinkR to="resume" data-aos="flip-right" data-aos-delay="400">
            <Resume />
          </LinkR>
          <a href="mailto:markstarbinski@gmail.com" data-aos="flip-right" data-aos-delay="600">
            <Email />
          </a>
        </SocialsWrapper>
       </Content>
       <ViewportBlock onEnterViewport={handleVisible} onLeaveViewport={handleVisible} />
    </ContactContainer>
  );
};

export default Contact;
