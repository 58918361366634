import React, { useState, useEffect } from "react";
import {
  ProjectsContainer,
  ProjectHeading,
  ProjectHr,
  Subtitle,
  Email,
  AppList,
  CategoryList,
  Category,
  CategoryBox,
} from "./ProjectGridElements";
import { WebApp } from "../Project-Card/ProjectCard";
import apps from "../../data/apps";

const Projects = () => {
  const [selected, setSelected] = useState(apps.pluginsAndThemes);
  const [data, setData] = useState([]);
  const [fade, setFade] = useState(true);
  const [fadeDir, setFadeDir] = useState("fadeUp");

  useEffect(() => {
    setData(selected.apps);
  }, [selected]);

  const changeCategory = (currentCategory) => {
    console.log(currentCategory);
    
    setSelected((prevValue) => {
      console.log(prevValue.id);
      if (prevValue.id === currentCategory.id) {
        setFadeDir("fadeUp");
      } else if (prevValue.id < currentCategory.id) {
        setFadeDir("fadeRight");
      } else {
        setFadeDir("fadeLeft");
      }
      return currentCategory;
    });
  };

  return (
    <ProjectsContainer id="projects" title="Projects" selected={selected}>
      <ProjectHeading data-aos="fade-right">Projects</ProjectHeading>
      <ProjectHr data-aos="fade-left" />
      <Subtitle data-aos="fade-right">
        These are a few projects I've worked on recently. Like what you see?{" "}
        <Email href="mailto:markstarbinski@gmail.com">Email Me.</Email>
      </Subtitle>
      <CategoryList data-aos="fade-right">
        <CategoryBox selected={selected} />
        {Object.keys(apps)
          .map((key) => apps[key])
          .map((heading) => (
            <Category
              key={heading.id}
              className={selected.id === heading.id ? "active" : null}
              onClick={() => {
                setFade(true);
                changeCategory(heading);
              }}
            >
              {heading.title}
            </Category>
          ))}
      </CategoryList>
      <AppList
        onAnimationEnd={() => {
          setFade(false);
        }}
        fadeDir={fadeDir}
        className={fade ? "animate" : ""}
      >
        {data.map((app) => {
          return (
            <WebApp
              key={app.appId}
              img={app.img}
              name={app.name}
              desc={app.desc}
              demoLink={app.demoLink}
              codeLink={app.codeLink}
            />
          );
        })}
      </AppList>
    </ProjectsContainer>
  );
};

export default Projects;
