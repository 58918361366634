import styled from 'styled-components';

export const InfoContainer = styled.div`
  background: white;
  z-index: 1;
  position: relative;
  width: 100%;
  min-height: 620px;
  padding: 50px 50px;
  transition: all 0.2s ease-in-out;

  @media screen and (max-width: 500px) {
    padding: 50px 0;
  }
`;

export const InfoHeading = styled.h1`
  text-align: center;
  font-size: 42px;
  color: black;

  @media screen and (max-width: 991px) {
    font-size: 44px;
  }

`;

export const InfoHr = styled.hr`
  border-style: solid;
  border-width: 4px 0 0 0; 
  border-color: black;
  margin: 20px auto 20px;
  width: 80px;

  @media screen and (max-width: 991px) {
    margin: 20px auto 30px;
  }
`;

export const InfoWrapper = styled.div`
  display: grid;
  z-index: 1;
  width: 100%;
  max-width: 1500px;
  margin-right: auto;
  margin-left: auto;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 991px) {
    padding: 20px 24px;
  }
`;

export const InfoRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  grid-template-areas: 'col1 col2';

  @media screen and (max-width: 991px) {
    grid-template-areas: 'col1' 'col2'
  }
`;

export const Column1 = styled.div`
  margin: 0 0 15px;
  grid-area: col1;

  @media screen and (max-width: 991px) {
    margin: auto;
  }
`;

export const Column2 = styled.div`
  margin: 0 0 15px;
  grid-area: col2;

  @media screen and (max-width: 991px) {
    margin: auto;
  }
`;

export const TextWrapper = styled.div`
  padding-top: 0;
  padding-bottom: 10px;
`;

export const Heading = styled.h1`
  margin-bottom: 34px;
  font-size: 42px;
  line-height: 1.1;
  font-weight: 600;
  color: black;

  @media screen and (max-width: 991px) {
    text-align: center;
  }
  @media screen and (max-width: 500px) {
    font-size: 40px;
  }
`;

export const Subtitle = styled.p`
  max-width: 440px;
  margin-bottom: 35px;
  font-size: 20px;
  line-height: 30px;
  color: black;
  font-family: 'Montserrat', sans-serif;

  @media screen and (max-width: 991px) {
    margin: auto;
    text-align: center;
    padding-bottom: 40px;
    font-size: 22px;
    max-width: 560px;
  }

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`;

export const ImgWrap = styled.div`
  max-width: 555px;
  height: 100%;
`;

export const Img = styled.img`
  width: 100%;
  margin: 0 0 10px 0;
  padding-right: 0;

  @media screen and (max-width: 991px) {
    text-align: center;
    height: 300px;
  }
`;
