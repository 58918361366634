import styled from 'styled-components';
import { FaReact, FaDatabase } from 'react-icons/fa';
import { BsKeyFill } from 'react-icons/bs';
import { MdArrowForward } from 'react-icons/md';


export const ReactIcon = styled(FaReact)`
  color: #00ADB5;
  display: block;
  margin: 10px auto 0;
  font-size: 100px;

`;

export const DatabaseIcon = styled(FaDatabase)`
  color: #00ADB5;
  display: block;
  margin: 10px auto 0;
  font-size: 90px;

`;

export const KeyIcon = styled(BsKeyFill)`
  color: #00ADB5;
  display: block;
  margin: -5px auto 0;
  font-size: 110px;

`;

export const TechnologyBg = styled.div`
  background: #E8E8E8;
  z-index: 1;
  position: relative;
  padding: 50px 50px;
  min-height: 620px;
  
  @media screen and (max-width: 500px) {
    padding: 50px 0;
  }
`;

export const TechnologyContainer = styled.div`
  max-width: 1110px;
  margin: auto;
`;

export const Heading = styled.h1`
  margin-bottom: 30px;
  font-size: 42px;
  line-height: 1.1;
  font-weight: 600;
  color: black;

  @media screen and (max-width: 991px) {
    text-align: center;
  }

  @media screen and (max-width: 500px) {
    font-size: 40px;
  }
`;

export const Subtitle = styled.p`
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 30px;
  color: black;
  font-family: 'Montserrat', sans-serif;

  @media screen and (max-width: 991px) {
    text-align: center;
    font-size: 22px;
    padding: 0 20px;
  }

  @media screen and (max-width: 500px) {
    font-size: 20px;
  }
`;

export const TechnologyWrapper = styled.div`
  display: grid;
  width: 100%;
  max-width: 1110px;
  margin: 0 auto 40px;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  grid-gap: 16px;


  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr;
    grid-gap: 36px;
    margin: 0 auto 50px;

  }
`;

export const TechCard = styled.div`
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  max-height: 280px;
  min-height: 280px;
  padding: 30px;
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
  }

  @media screen and (max-width: 991px) {
    min-width: 500px;
    margin: 0 auto;
  }

  @media screen and (max-width: 620px) {
    min-width: 400px;
  }

  @media screen and (max-width: 500px) {
    min-width: 300px;

  }
`;

export const TechHeading = styled.h1`
  font-size: 24px;
  padding-bottom: 10px;
  text-align: center;

  @media screen and (max-width: 991px) {
    font-size: 28px;
  }
`;

export const TechDesc = styled.p`
  font-size: 18px;
  line-height: 24px;
  max-width: 280px;
  margin: auto;
  text-align: center;
  font-family: 'Montserrat', sans-serif;

  @media screen and (max-width: 991px) {
    font-size: 20px;
  }
`;

export const ArrowForward = styled(MdArrowForward)`
  margin-left: 8px;
  font-size: 20px;
`;

export const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
`;
