import React, { useState } from "react";
import {
  colors,
  AppContainer,
  AppBrowser,
  AppCircle,
  Img,
  AppTitle,
  AppDesc,
  UtilityList,
  ContentWrapper,
} from "./ProjectCardElements";
import { AppButtons } from "../Button/ButtonElement";

export const WebApp = ({ img, name, desc, demoLink, codeLink }) => {
  const [isHovered, setIsHovered] = useState(false);

  function handleHover() {
    setIsHovered(!isHovered);
  }

  return (
    <AppContainer>
      <AppBrowser>
        {colors.map((color) => (
          <AppCircle key={color.name} color={color.color} />
        ))}
      </AppBrowser>
      <ContentWrapper onMouseEnter={handleHover} onMouseLeave={handleHover}>
        <Img src={require(`../../assets/${img}`)} />
        <AppTitle isHovered={isHovered}>{name}</AppTitle>
        <AppDesc isHovered={isHovered}>{desc}</AppDesc>
        <UtilityList isHovered={isHovered}>
          {codeLink && (
            <AppButtons href={codeLink}>Code</AppButtons>
          )}
          {demoLink && (
            <AppButtons href={demoLink}>Demo</AppButtons>
          )}
        </UtilityList>
      </ContentWrapper>
    </AppContainer>
  );
};
