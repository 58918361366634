const apps = {
  pluginsAndThemes: {
    id: "1",
    key: "pluginsAndThemes",
    title: "Plugins & Themes",
    apps: [
      {
        appId: 1,
        name: "SVDP WNY Theme",
        desc: "Custom theme designed and built for St. Vincent De Paul of West New York.",
        img: "svdp.png",
        demoLink: "https://svdp.starbinski.com",
      },
      {
        appId: 2,
        name: "WordPress Template",
        desc: "Flexible and fully scalable WordPress starter theme built with full site editing and page templates.",
        img: "custom.png",
        demoLink: "https://dev.starbinski.com/",
        codeLink: "https://github.com/mtstarbinski/wordpress-theme-template",
      },
      {
        appId: 3,
        name: "Custom Widgets Plugin",
        desc: "Easily configurable plugin built to extend the functionality of WordPress by adding custom widgets.",
        img: "custom-plugin.png",
        demoLink: "https://dev.starbinski.com/experimental-page/",
        codeLink: "https://github.com/mtstarbinski/Custom-Widgets-Plugin",
      },
      {
        appId: 4,
        name: "Greeting API Plugin",
        desc: "A WordPress plugin that allows the admin to send and receive a greeting to another WordPress site through OAuth 2.",
        img: "greeting-api.png",
        demoLink: "https://dev.starbinski.com/experimental-page/",
        codeLink: "https://github.com/mtstarbinski/greeting-api",
      },
    ],
  },
  react: {
    id: "2",
    key: "react",
    title: "React.js",
    apps: [
      {
        appId: 1,
        name: "Crown Clothing",
        desc: "Full E-commerce web app built with React, Redux, Styled-Components, Router, Stripe, and Firebase.",
        img: "crown.jpg",
        demoLink: "https://crown-designer-clothes.netlify.app/",
        codeLink:
          "https://github.com/mtstarbinski/Crown-Clothing-V2.0/tree/Redux",
      },
      {
        appId: 2,
        name: "Admin Dashboard",
        desc: "Dashboard built with React using Router and Chart.js. Features different graphs and tables to monitor data.",
        img: "dash.jpg",
        demoLink: "https://react-admin-dash.netlify.app/",
        codeLink: "https://github.com/mtstarbinski/Admin-Dashboard",
      },
      {
        appId: 3,
        name: "Trending Movies",
        desc: "React web app utilizing TMDB's API to fetch and map the current trending Movies and TV Shows.",
        img: "trend.png",
        demoLink: "https://current-movie-trends.netlify.app",
        codeLink: "https://github.com/mtstarbinski/Trending-Movies",
      },
      {
        appId: 4,
        name: "Keeper",
        desc: "Interactive web app where a user can create and delete notes. Built in React, using components, hooks, and props.",
        img: "keeper.jpg",
        demoLink: "https://mtstarbinski.github.io/keeper/",
        codeLink: "https://github.com/mtstarbinski/keeper",
      },
      {
        appId: 5,
        name: "To-Do List 2.0",
        desc: "A second version of the to-do list built in React using components, props and use state.",
        img: "to-do2.jpg",
        demoLink: "https://mtstarbinski.github.io/react-to-do-list/",
        codeLink: "https://github.com/mtstarbinski/react-to-do-list",
      },
      {
        appId: 6,
        name: "React Resume",
        desc: "Resume built with React by mapping a JSON object to reusable components.",
        img: "resume.jpg",
        demoLink: "https://mtstarbinski.github.io/Resume-React.js/",
        codeLink: "https://github.com/mtstarbinski/Resume-React.js",
      },
    ],
  },
  node: {
    id: "3",
    key: "node",
    title: "Node.js",
    apps: [
      {
        appId: 1,
        name: "Newsletter",
        desc: "Implemented Mailchimp's API to build a simple newsletter signup page with Node.js.",
        img: "newsletter.jpg",
        demoLink: "https://mailchimp-newsletter-vd3m.onrender.com/",
        codeLink: "https://github.com/mtstarbinski/Mailchimp-Newsletter",
      },
      {
        appId: 2,
        name: "To-Do List",
        desc: "Interactive to-do list web app built in Node. The app uses Express, EJS and MongoDB.",
        img: "to-do.jpg",
        demoLink: "https://daily-to-do-list.onrender.com/",
        codeLink: "https://github.com/mtstarbinski/Daily-ToDo-List",
      },
      {
        appId: 3,
        name: "Daily Blog",
        desc: "Interactive web app where a user can create and delete blog posts. Built using Node, Express, EJS and MongoDB.",
        img: "blog.jpg",
        demoLink: "https://daily-blog-dx7y.onrender.com/",
        codeLink: "https://github.com/mtstarbinski/Daily-Blog",
      },
      {
        appId: 4,
        name: "Secrets",
        desc: "Anonymous secret sharing web app built with full authentication using Passport.js, and third party login with Google.",
        img: "secrets.jpg",
        demoLink: "https://secrets-app-5prr.onrender.com/",
        codeLink: "https://github.com/mtstarbinski/secrets-app",
      },
      {
        appId: 5,
        name: "BrewCo Backend",
        desc: "Backend for an E-commerce coffee shop. REST API written with Express.js and MongoDB. Testing/logging done with Winston.",
        img: "brewco.jpg",
        codeLink: "https://github.com/mtstarbinski/BrewCo",
      },
    ],
  },
  javascript: {
    id: "4",
    key: "javascript",
    title: "JavaScript",
    apps: [
      {
        appId: 1,
        name: "Tindog",
        desc: "Sample webpage built with Bootstap 5 and advanced CSS3 techniques.",
        img: "tindog.jpg",
        demoLink: "https://mtstarbinski.github.io/tindog/",
        codeLink: "https://github.com/mtstarbinski/tindog",
      },
      {
        appId: 2,
        name: "Drum Kit",
        desc: "Interactive drum kit web app built in HTML5, CSS3 and JavaScript",
        img: "drum.jpg",
        demoLink: "https://mtstarbinski.github.io/Drum-Kit/",
        codeLink: "https://github.com/mtstarbinski/Drum-Kit",
      },
      {
        appId: 3,
        name: "Simon",
        desc: "Interactive game of Simon built in HTML5, CSS3, and JQuery.",
        img: "simon.jpg",
        demoLink: "https://mtstarbinski.github.io/Simon/",
        codeLink: "https://github.com/mtstarbinski/Simon",
      },
      {
        appId: 4,
        name: "Dice",
        desc: "Interactive Dice web app built with HTML5, CSS3, and JavaScript.",
        img: "dice.jpg",
        demoLink: "https://mtstarbinski.github.io/Dice-Game/",
        codeLink: "https://github.com/mtstarbinski/Dice-Game",
      },
    ],
  },
};

export default apps;
