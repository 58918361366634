import React from "react";
import {TechnologyBg, TechnologyContainer, TechnologyWrapper, Subtitle, TechCard, TechHeading, TechDesc, ReactIcon, DatabaseIcon, KeyIcon, BtnWrap, Heading, ArrowForward } from "./TechnologyElements";
import { ButtonFill } from "../Button/ButtonElement";

const Technology = () => {

  return (
    <TechnologyBg>
    <TechnologyContainer>
      <Heading data-aos="fade-right">What can he do?</Heading>
      <Subtitle data-aos="fade-right">I've worked with a wide range of technologies in the development world. </Subtitle>
      <TechnologyWrapper>
          <TechCard data-aos="flip-left">
            <TechHeading>Front-End</TechHeading>
            <TechDesc>Experience building web apps using HTML5, CSS3, and JS frameworks like React.</TechDesc>
            <ReactIcon/>
          </TechCard>
          <TechCard data-aos="flip-left">
            <TechHeading >Back-End</TechHeading>
            <TechDesc>Experience building REST APIs and managing databases using Node, Express, Django and MongoDB.</TechDesc>
            <DatabaseIcon/>
          </TechCard>
          <TechCard data-aos="flip-left">
            <TechHeading>Authentication</TechHeading>
            <TechDesc>Experience building secure apps using Auth0, Passport, Session and Firebase.</TechDesc>
            <KeyIcon/>
          </TechCard>
      </TechnologyWrapper>
      <BtnWrap data-aos="fade-up">
        <ButtonFill to='projects' smooth={true} duration={1000} spy={true} exact="true" offset={-80}>
          View my work <ArrowForward />
        </ButtonFill>
      </BtnWrap>
    </TechnologyContainer>
    </TechnologyBg>
  )
}

export default Technology;
