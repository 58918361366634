import React from 'react';
import { pdfjs, Page } from 'react-pdf';
import resume from '../../assets/resume.pdf';
import { Nav, NavbarContainer, NavLogo, Doc, ResumeWrapper} from './RenderResumeElements';

const Resume = () => {

  pdfjs.GlobalWorkerOptions.workerSrc =
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  function handleClick(event) {
    event.preventDefault();
  }

  return (
      <div onContextMenu={handleClick}>
        <Nav>
          <NavbarContainer>
            <NavLogo to='/'>starbinski</NavLogo>
          </NavbarContainer>
        </Nav>
        <ResumeWrapper>
          <Doc file={ resume }>
            <Page renderTextLayer={true} renderAnnotationLayer={false} height={1000} pageNumber={1}/>
          </Doc>
      </ResumeWrapper>
      </div>
  );
}
export default Resume;
