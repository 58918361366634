import React from "react";
import {InfoContainer, InfoWrapper, InfoHr, InfoHeading, InfoRow, Column1, Column2, TextWrapper, ImgWrap, Heading, Subtitle, Img} from "./InfoElements";
import aboutImg from "../../assets/about.svg"

const Info = () => {
  return (
      <InfoContainer>
        <InfoHeading data-aos="fade-right">About</InfoHeading>
        <InfoHr data-aos="fade-left" data-aos-delay="200"/>
        <InfoWrapper>
          <InfoRow>
            <Column1>
              <TextWrapper>
                <Heading data-aos="fade-right" data-aos-delay="300">Who is this guy?</Heading>
                <Subtitle data-aos="fade-right" data-aos-delay="400">I'm a graduate of Montclair State University with a BS in Information Technology. I have a serious passion for technology and understanding how things work. I love to create beautiful and responsive applications that are easy to use and built with the best practices.</Subtitle>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img data-aos="fade-in" data-aos-delay="500" src={aboutImg}/>
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
  )
}

export default Info;
