import { useState, useEffect } from "react";
import Navbar from "../components/Navbar/Navbar";
import Sidebar from "../components/Sidebar/Sidebar";
import Hero from "../components/Hero/Hero";
import Info from "../components/Info/Info";
import Technology from "../components/Technology/Technology";
import Projects from "../components/Project-Grid/ProjectGrid";
import Contact from "../components/Contact/Contact";
import ScrollToTop from '../utils/ScrollToTop';

function Home() {
  const [isOpen, setIsOpen] = useState(false);
  const [footerVisible, setFooterVisible] = useState(false);
  const [scrollNav, setScrollNav] = useState(false);

  const ChangeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true);
    } else setScrollNav(false);
  }

  useEffect(() => {
    window.addEventListener('scroll', ChangeNav)

    return () =>{
      window.removeEventListener('scroll', ChangeNav)
    }
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

    return (
      <>
        <ScrollToTop />
        <Navbar toggle={toggle} isVisible={footerVisible} scrollNav={scrollNav}/>
        <Sidebar isOpen={isOpen} toggle={toggle} />
        <Hero scrollNav={scrollNav}/>
        <section id="about" title="About">
          <Info />
          <Technology />
        </section>
        <Projects />
        <Contact isVisible={footerVisible} setIsVisible={setFooterVisible} />
      </>
    );
};

export default Home;
