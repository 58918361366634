import styled from "styled-components";

export const AppContainer = styled.div`
  width: 350px;
  height: 300px;
  margin: 20px 10px;
  border: 3px solid #222222;
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  text-align: center;
  position: relative;
  background-color: #222222;

  @media screen and (max-width: 360px) {
    margin: 20px 0;
  }

`;

export const AppBrowser = styled.div`
  height: 20px;
  width: 100%;
  background-color: #222222;
  display: flex;
  align-items: center;
  position: relative;
`;

export const colors = [
  {
    name: "red",
    color: "#fc625d"
  },
  {
    name: "yellow",
    color: "#fdbc40"
  },
  {
    name: "green",
    color: "#35cd4b"
  },
];

export const AppCircle = styled.div`
  height: 6px;
  width: 6px;
  border-radius: 50%;
  margin: 3px;
  background-color: ${props => props.color};
`;

export const ContentWrapper = styled.div`
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: black;

    Img {
      transition: all 0.2s ease;
      opacity: 0.1;
  }
}
`;

export const Img = styled.img`
  height: 300px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  width: 100%;
  object-fit: cover;
`;

export const AppTitle = styled.h1`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: auto;
  font-size: 2rem;
  color: white;
  opacity: ${({ isHovered }) => (isHovered ? "100%" : "0")};
  transform: translateY(${({ isHovered }) => (isHovered ? "40px" : "0")});
  transition: all 0.2s cubic-bezier(0.02, 0.24, 0.45, 1.54);
`;

export const AppDesc = styled.p`
  top: 0;
  font-family: 'Montserrat', sans-serif;
  padding: 0 10px;
  color: white;
  font-size: 1.4rem;
  position: absolute;
  opacity: ${({ isHovered }) => (isHovered ? "100%" : "0")};
  transform: translateY(${({ isHovered }) => (isHovered ? "80px" : "0")});
  transition: all 0.2s cubic-bezier(0.02, 0.24, 0.45, 1.54);
`;

export const UtilityList = styled.ul`
  width: 100%;
  bottom: 0;
  position: absolute;
  list-style-type: none;
  padding: 0;
  display: flex;
  justify-content: space-around;
  opacity: ${({ isHovered }) => (isHovered ? "100%" : "0")};
  pointer-events: ${({ isHovered }) => (isHovered ? "100%" : "0")};
  transform: translateY(${({ isHovered }) => (isHovered ? "-30px" : "0")});
  transition: all 0.2s cubic-bezier(0.02, 0.24, 0.45, 1.54);

`;
