import React from "react";
import RenderResume from "../components/Render-Resume/RenderResume.jsx";
import ScrollToTop from '../utils/ScrollToTop';

const Resume = () => {
  return (
    <>
      <ScrollToTop/>
      <RenderResume />
    </>
  );
};

export default Resume;
