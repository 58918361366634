import { useState, useRef } from "react";
import { HeroContainer, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward} from "./HeroElements";
import { ButtonOutline } from "../Button/ButtonElement";
import Video from '../../assets/video.mp4';

const Hero = ({scrollNav}) => {
  const [playing, setPlaying] = useState(true)
  var vidRef = useRef(null);

  if(scrollNav && playing){
      vidRef.current.pause();
      setPlaying(false);
  } else if(!scrollNav && !playing){
    vidRef.current.play();
    setPlaying(true);
  }

  return (
    <HeroContainer id="hero" title="Hero">
        <VideoBg ref={vidRef} autoPlay loop muted playsInline src={Video} type='video/mp4' />
      <HeroContent>
        <HeroH1>Hi, I'm Mark Starbinski.</HeroH1>
        <HeroP>full stack developer</HeroP>
        <HeroBtnWrapper>
          <ButtonOutline to='about' smooth={true} duration={1000} spy={true} exact="true" offset={-80}>
            Learn more <ArrowForward />
          </ButtonOutline>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
