import React, { useEffect } from "react";
import Home from "./pages/Home";
import { default as Resume } from "./pages/Resume";
import {
  Routes,
  Route,
  Navigate
} from 'react-router-dom';
import Aos from "aos";
import "aos/dist/aos.css";

function App() {

  useEffect(() => {
    Aos.init({ duration: 1000, once: true, offset: 0});
  }, []);

  return (
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/resume' element={<Resume />}/>
        <Route path='*' element={<Navigate to="/" />} />
      </Routes>
  );
}
export default App;
