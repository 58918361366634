import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';

export const ButtonOutline = styled(LinkS)`
  color: white;
  white-space: nowrap;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  border: solid;
  outline: none;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;
    background: #00ADB5;
    color: white;
    border-color: #00ADB5;

    svg {
      transition: all 0.3s ease;
      transform: rotateZ(90deg);
    }
  }

  svg {
    transition: all 0.3s ease;
  }
`;

export const ButtonFill = styled(LinkS)`
  color: white;
  white-space: nowrap;
  padding: 12px 30px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  outline: none;
  border: solid;
  border-color: #00ADB5;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  background: #00ADB5;

  &:hover {
    transition: all 0.3s ease-in-out;
    color: white;
    border-color: black;
    background: black;

    svg {
      transition: all 0.3s ease;
      transform: rotateZ(90deg);
    }
  }

 svg {
   transition: all 0.3s ease;
 }
`;

export const AppButtons = styled.a`
  color: white;
  white-space: nowrap;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 1rem;
  outline: none;
  border: solid;
  border-color: white;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  background: none;
  text-decoration: none;

  &:hover {
    transition: all 0.3s ease-in-out;
    color: white;
    border-color: #00ADB5;
    background: #00ADB5;
  }
`;
